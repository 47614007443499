import ReactDOM from 'react-dom'
import { createRoot, hydrateRoot } from 'react-dom/client'

import { FAQ_URL } from 'components/constants'

import './src/global.css'

const EXCLUDED_BY_URL = [FAQ_URL]
const EXCLUDED_BY_REGEXP = [/^\/blog(\/\d+)?\/?$/]

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname: prevPathname } = prevRouterProps?.location ?? {}
  const { pathname, hash } = location

  const isExcludedByUrl = EXCLUDED_BY_URL.some(
    (item) => pathname.includes(item) && prevPathname?.includes(item)
  )

  const isExcludedByRegexp = EXCLUDED_BY_REGEXP.some(
    (item) => item.test(pathname) && item.test(prevPathname)
  )

  if (isExcludedByUrl || isExcludedByRegexp) {
    return false
  }

  if (hash) {
    setTimeout(() => {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 0)

    return false
  }

  const currentPosition = getSavedScrollPosition(location)

  setTimeout(() => window.scrollTo(...(currentPosition ?? [0, 0])), 0)

  return true
}

// This ensures that `ReactDOM.hydrate` is replaced with `hydrateRoot`
export const onClientEntry = () => {
  // Override hydrate to use hydrateRoot
  ReactDOM.hydrate = (...args) => {
    const container = args[1]
    const element = args[0]

    // Use hydrateRoot instead of ReactDOM.hydrate
    hydrateRoot(container, element)
  }

  // Override render to use createRoot
  ReactDOM.render = (...args) => {
    const container = args[1]
    const element = args[0]

    // Use createRoot instead of ReactDOM.render
    const root = createRoot(container)
    root.render(element)
  }
}
